<template>
    <div>
        <div style="width:100%; text-align: right; margin-bottom:10px"> 
            <Button type="primary" icon="ios-add" @click="openAdd" style="margin-left: 10px">新增关联内容</Button>
        </div>
        
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :hidePage="true"
        ></LiefengTable>
        <LiefengModal
            :title=" editType == 'add' ?'新增':'修改'"
            :value="editStatus"
            @input="changeEditStatus"
            width="600px"
            height="450px"
            >
            <template v-slot:contentarea>
                <Form :label-width="120">
                    <FormItem>
                        <span slot="label" class="validate">标题:</span>
                        <Input v-model.trim="formData.title" style="width: 300px" ></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">关联类型:</span>
                        <RadioGroup v-model="formData.businessType">
                            <Radio label="-5">内容详情</Radio>
                            <Radio label="-1">内容列表</Radio>
                            <Radio label="-2">页面</Radio>
                            <Radio label="-3">第三方链接</Radio>
                            <Radio label="-4">小程序</Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate" >跳转链接:</span>
                        <Input v-model.trim="formData.link" style="width: 300px"></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate" >排序:</span>
                        <InputNumber :min="1" :max='999999999' v-model="formData.sort"></InputNumber>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">背景:</span>
                        <RadioGroup v-model="formData.photoType">
                            <!-- <Radio label="icon">图标</Radio> -->
                            <Radio label="image">背景</Radio>
                        </RadioGroup>
                        <LiefengUpload ref="imgData" v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList='imgData' :showView="true"></LiefengUpload>
                    </FormItem>
                    <FormItem>
                        <span slot="label">位置:</span>
                        <RadioGroup v-model="formData.position">
                            <Radio label="0">置下</Radio>
                            <Radio label="1">置上</Radio>
                            <Radio label="2">悬浮</Radio>
                        </RadioGroup>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right:10px" :loading="btnLoading" type="primary" @click="save">保存</Button>
                <Button style="margin-right:10px" type="info" @click="changeEditStatus(false);">关闭</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import LiefengUpload from '@/components/LiefengUpload';

export default {
    components:{
        LiefengTable,
        LiefengModal,
        LiefengUpload
    },
    props:['quesDate','showNum'],
    data () {
        return {
            tableColumns:[
                {
                    title: '标题',
                    minWidth:400,
                    align: "center",
                    key: 'title'
                },
                {
                    title: '类型',
                    minWidth:120,
                    align: "center",
                    key: 'businessType',
                    render:(h,params) => {
                        return h(
                            'span',
                            {
                                style:{
                                    dispiay:'inline-block',
                                    backgroundColor: '#D3F4F4',
                                    padding:"20px 10px",
                                    color:"#24C8C8"
                                }
                            },
                            params.row.businessType=='-5'?'内容详情':params.row.businessType=='-1'?'内容列表':params.row.businessType=='-2'?'页面':params.row.businessType=='-3'?'第三方链接':params.row.businessType == '0' ? '信息':'小程序'
                        )
                    }
                },

                {
                    title: '链接',
                    minWidth:400,
                    align: "center",
                    key: 'link'
                },
                {
                    title: '排序',
                    minWidth:100,
                    align: "center",
                    key: 'sort'
                },
                {
                    title: '背景',
                    minWidth:200,
                    align: "center",
                    render: (h, params) => {
                        return h("div",
                            [h("Avatar",
                            {
                                props:{
                                    src: params.row.photo
                                }
                            })
                            ]
                        )
                    }
                },
                {
                    fixed:'right',
                    title: "操作",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small",
                            },
                            style: {
                                marginLeft: "14px",
                            },
                            on: {
                                click: () => {
                                    this.edit(params.row.id);
                                },
                            },
                            },
                            "修改"
                        ),
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small",
                            },
                            style: {
                                marginLeft: "14px",
                            },
                            on: {
                                click: () => {
                                    this.delete(params.row.id);
                                },
                            },
                            },
                            "删除"
                        ),
                        ]);
                    },
                    },
            ],
            tableData: [
            ],
            loading:false,
            page:1,
            pageSize:10,
            tatal:0,
            btnLoading:false,
            
            editType:'',
            editStatus:false,
            formData:{
                businessType:'-1',
                photoType:'image',
                position:'0',
                sort:1
            },
            showImage:true,
            imgData:[]
 
        }
    },
    methods:{
        changeEditStatus(val){
            if(!val)
            this.formData = {
                businessType:'-1',
                photoType:'image',
                position:'0',
                sort:1
            },
            this.imgData = []
            this.showImage = false;
            this.$nextTick(()=>{this.showImage = true;})
            this.editStatus = val
        },
        hadlePageSize(){

        },
        openAdd(){
            console.log(123)
            this.editType = 'add'
            this.editStatus = true
            this.imgData = []
            this.showImage = false;
            this.$nextTick(()=>{this.showImage = true;})
        },
        edit(id){
            this.editType = 'upData'
            this.editStatus = true
            this.$get('/datamsg/api/pc/information/link/selectById.ac',{
                id
            }).then( res => {
                if(res.code == 200){
                    
                    let self = this
                    this.showImage = false
                    this.$nextTick(()=>{
                        this.showImage = true
                        let data = JSON.parse(JSON.stringify(res.data))
                        self.formData.title = data.attrs[0].attrValue
                        self.imgData = [{ name: "", url: data.attrs[1].attrValue }] 
                        self.formData.photoType = data.attrs[2].attrValue
                        self.formData.position = data.attrs[3].attrValue
                        self.formData.businessType = data.businessType
                        this.formData.id = data.id
                        this.formData.infoId = data.infoId
                        this.formData.link = data.link
                        this.formData.functionType = data.functionType
                        this.formData.infoCode = data.infoCode
                        this.formData.sort = data.sort
                        this.formData.parentId = data.parentId
                        this.formData.status = data.status
                        console.log(data)
                        console.log(self.formData)
                    })
                    
                }
            })
        },
        delete(id){
            this.$Modal.confirm({
            title: "删除确定",
            content: "是否删除当前链接",
            onOk: () => {
            this.$get('/datamsg/api/pc/information/link/deleteLink',{
                id
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    this.getList()
                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
            },
        });
        },
        async getList(){
            await this.$get('/datamsg/api/pc/information/link/selectChildListAndBusiness',{
                parentInfoId:this.quesDate.id,
                businessType:this.quesDate.businessType,
                functionType:this.quesDate.functionType,
                infoCode:''
            }).then( res => {
                if( res.code == 200){
                    this.tableData = res.dataList
                }else{
                    this.$Message.error({
                        content:"相关链接获取失败",
                        background:true
                    })
                }
            })
        },
        save(){
            if(!this.formData.title){
                this.$Message.warning({
                    content:"请输入标题",
                    background:true
                })
                return
            }
            if(!this.formData.businessType){
                this.$Message.warning({
                    content:"请选择关联类型",
                    background:true
                })
                return
            }
            if(!this.formData.link){
                this.$Message.warning({
                    content:"请输入跳转链接",
                    background:true
                })
                return
            }
            if(!this.formData.photoType){
                this.$Message.warning({
                    content:"请选择背景类型",
                    background:true
                })
                return
            }
            if(this.$refs.imgData.uploadList.length == 0){
                this.$Message.warning({
                    content:"请添加图片",
                    background:true
                })
                return
            }else{
                this.formData.imgData = this.$refs.imgData.uploadList[0].url
            }
            if(this.editType == 'add'){
                this.add()
            }else {
                this.upData()
            }
            
        },
        add(){
            this.btnLoading = true
            let attrs = []
            attrs[0] = {attrKey:'title',attrValue:this.formData.title}
            attrs[1] = {attrKey:'photo',attrValue:this.formData.imgData}
            attrs[2] = {attrKey:'photoType',attrValue:this.formData.photoType}
            attrs[3] = {attrKey:'position',attrValue:this.formData.position}
            console.log(attrs)
            this.$post('/datamsg/api/pc/information/link/addLinkOthers',{
                parentId:this.parentId,
                functionType:this.quesDate.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                businessType:this.formData.businessType,
                link:this.formData.link,
                status:1,
                attrs:attrs,
                sort:this.formData.sort
            },{ "Content-Type": "application/json" }).then( res => {
                this.btnLoading = false
                if(res.code == 200){
                    this.$Message.success({
                        content:"新增成功",
                        background:true
                    })
                    this.showImage = false;
                    this.$nextTick(()=>{this.showImage = true;})
                    this.editStatus = false
                    this.getList()
                }else {
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                }
            })
        },
        upData(){
            this.btnLoading = true
            let attrs = []
            attrs[0] = {attrKey:'title',attrValue:this.formData.title}
            attrs[1] = {attrKey:'photo',attrValue:this.formData.imgData}
            attrs[2] = {attrKey:'photoType',attrValue:this.formData.photoType}
            attrs[3] = {attrKey:'position',attrValue:this.formData.position}
            delete this.formData.imgData
            delete this.formData.photoType
            delete this.formData.title
            delete this.formData.position
            this.$post('/datamsg/api/pc/information/link/updateLinkOthers',{
                attrs:attrs,
                ...this.formData
            },{ "Content-Type": "application/json" }).then( res => {
                this.btnLoading = false
                if(res.code == 200){
                    this.$Message.success({
                        content:"修改成功",
                        background:true
                    })
                    this.showImage = false;
                    this.$nextTick(()=>{this.showImage = true;})
                    this.editStatus = false
                    this.getList()
                }else {
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                }
            })
        },
        async getSelectByBusiness(){
            console.log(this.quesDate)
            await this.$get('/datamsg/api/pc/information/link/selectByBusiness',{
                parentInfoId:this.quesDate.id,
                businessType:this.quesDate.businessType
            }).then( res => {
                if(res.code == 200){
                    if(!res.data){
                        // 初始化
                            this.$post('/datamsg/api/pc/information/link/addLinkOthers',{
                            functionType:this.quesDate.functionType,
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            businessType:this.quesDate.businessType,
                            infoCode:this.quesDate.infoCode,
                            status:1,
                            parentId:0,
                            infoId:this.quesDate.id
                        },{ "Content-Type": "application/json" }).then( res => {
                            if(res.code == 200){
                                this.parentId = res.data.id
                            }else{
                                this.$Message.error({
                                content:res.desc,
                                background:true
                            })
                            }
                        })
                    }else {
                        this.parentId = res.data.id
                    }
                    
                }
            })
        }
    },
    watch:{
        showNum:{
            handler(val){
                this.getSelectByBusiness()
                this.getList()
                
            }
        },
        immediate: true
    }
}
</script>
 
<style lang="less" scoped>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>